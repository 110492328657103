@import 'styles/base';

.PageLayout__MyListings {
  .MyListings__page-content {
    @media #{$max-1200} {
      padding: 10px;
    }
  }
}

.price-button {
  border: none;
  background-color: transparent !important;
  box-shadow: none;
  padding: 0;
  width: 93%;

  p, span {
    color: #ff9100 !important;
    font-weight: 400;
    margin-left: 0 !important;
  }

  p {
    text-align: justify;
  }
}