@import 'styles/base';

.WidgetContainer {
  box-sizing: border-box;
  background: $white;
  margin-bottom: 24px;
  border-radius: 3px;
  &.highlighted {
    border: 1px solid $yellow;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid greyScale(0.15);

    div {
      display: flex;
    }

    &--icon {
      font-size: 20px;
      margin-right: 8px;

      &.highlighted {
        color: $yellow;
      }
    }

    h4 {
      color: $font-color-dark-1;
      font-size: 16px;
      font-weight: bold;
      margin: 0;
    }

    a {
      color: $base-font-color;
      font-size: 13px;
      font-weight: bold;
    }
  }
}
