@import "styles/base";

.notification-error {
  .ant-notification-notice-message {
    color: $error;
  }
}
.notification-error__list {
  padding-left: 25px;
}
