@import 'styles/base';

.BaseLayout--BreederAdminLayout {
  .ant-breadcrumb-link {
    color: greyScale(0.45);
  }
  .BaseLayout__body {
    background-color: $white;
    padding: 16px;
    min-height: calc(100vh - 64px);
  }
}