@import 'styles/base'; 

.PuppyGenderIcon {
  padding: 5px;
  border-radius: 100%;

  &__sire {
    background-color: $blue-1;
  }

  &__dam {
    background-color: $pink-1;
  }
}
