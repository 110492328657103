@import 'styles/base';

html body {
  background-color: white;
}

.Authorization {
  font-size: $font-small;
  display: flex;
  flex-direction: column;
  @media #{$min-1200} {
    flex-direction: row;
  }
}

.Authorization .ant-btn-loading-icon {
  display: inline;
  margin-right: 5px;
}

.Authorization__bh-logo {
  margin-bottom: 50px;
  @media #{$min-1200} {
    margin-top: 25%;
  }
}

.Authorization__title {
  font-size: 55px;
  margin-bottom: 25px;
  font-weight: bold;
  line-height: 40px;
  color: $font-color-dark-1;
}

.Authorization__subtitle {
  font-size: 19px;
  font-weight: normal;
  line-height: normal;
  margin-top: 10px;
  color: greyScale(0.65);
}

.Authorization__img {
  height: 220px;
  width: 220px;
  margin: 25px 25px 25px 0;
  @media #{$min-1200} {
    display: none;
  }
}

.Authorization__img--xl {
  display: none;
  @media #{$min-1200} {
    width: 370px;
    height: 370px;
    display: block;
    margin-top: 100px;
  }
}

.Authorization__img-wrap--xl {
  flex: 1;
  max-width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Authorization__submit {
  background: $orange-6;

  &.ant-btn {
    height: 40px;

    span {
      display: inline-block;
      margin-top: -1px;
    }
  }
}

.Authorization__margin {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.Authorization__margin--xl {
  width: 100%;
  @media #{$min-1200} {
    max-width: 410px;
  }
}

.Authorization__footer {
  background: $blue-1;
  padding: 15px;
  @media #{$min-1200} {
    margin: 0 20px;
  }
}

.Authorization__footer p {
  margin-bottom: 0;

  &:last-of-type {
    margin-top: 7px;
  }
}

.Authorization__no-main-page {
  @media #{$min-1200} {
    margin: 0 auto;
  }

  .Authorization__title {
    font-size: 26px;
  }

  .Authorization__subtitle {
    font-size: 19px;
  }

  .Authorization__footer {
    @media #{$max-1200}{
      position: fixed;
      width: 100%;
      bottom: 0;
    }
  }
}



.Authorization .ant-btn-primary:active,
.Authorization .ant-btn-primary:focus {
  color: $white;
  background: $orange-6;
  border-color: $orange-6;
}
