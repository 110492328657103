@import 'styles/base';

.MyParentsSearch {
  background-color: $background;
  label {
    font-size: 14px;
  }
  .ant-select-selector {
    font-size: 14px;
  }
}
.MyParentsSearch--small {
  padding: 10px 8px 0;
}
.MyParentsSearch--large {
  padding: 0 0 10px;

  .ant-input-search {
    width: 246px;
  }
}
