@import 'styles/base';

.EntityListColumn {
  &.ant-card {
    background-color: transparent;
  }

  .ant-card-head {
    background-color: $lighter-gray;
    border-radius: $border-radius;
    color: greyScale(0.85);
    padding: 0 0.75em;
    min-height: auto;
    margin-bottom: 0.5em;

    .ant-card-head-title {
      padding: 0.5em 0;
    }

    .ant-badge-count {
      background: transparent;
      color: rgba(0, 0, 0, 0.85);
      box-shadow: 0 0 0 1px greyScale(0.85);
    }
  }

  .ant-card-body {
    padding: 0;
  }
}

.EntityListColumn__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.05em;
  text-transform: capitalize;
}

.EntityListColumn__content {
  overflow-y: auto;
  overflow-x: hidden;
}
