@import 'styles/base';

$fields-theme-color-7: $orange-7;
$fields-theme-color-6: $orange-6;
$fields-theme-color-5: $orange-5;
$fields-theme-color-4: $orange-4;
$fields-theme-color-3: $orange-3;
$fields-theme-color-2: $orange-2;
$fields-theme-color-1: $orange-1;
$field-box-shadow-color: rgba($fields-theme-color-6, 0.2);
$field-box-shadow-color-light: rgba($fields-theme-color-6, 0.08);
$form-large-font-size: 16px;
$field-disabled-color: greyScale(0.55);

.ant-input,
.ant-input-number {
  &:hover {
    border-color: $fields-theme-color-5;
  }
  &:focus {
    border-color: $fields-theme-color-5;
    -webkit-box-shadow: 0 0 0 2px $field-box-shadow-color;
    box-shadow: 0 0 0 2px $field-box-shadow-color;
  }
  &[disabled] {
    color: $field-disabled-color;
  }
}
.ant-input-number-disabled {
  &:hover {
    border-color: $default-input-border-color;
  }
}
.ant-input-focused,
.ant-input-number-focused {
  border-color: $fields-theme-color-5;
  -webkit-box-shadow: 0 0 0 2px $field-box-shadow-color;
  box-shadow: 0 0 0 2px $field-box-shadow-color;
}

.ant-form-item-has-warning .ant-input,
.ant-form-item-has-warning .ant-input-affix-wrapper,
.ant-form-item-has-warning .ant-input:hover,
.ant-form-item-has-warning .ant-input-affix-wrapper:hover {
  background-color: rgba($warning, 0.25);
  border-color: $warning;
}
.ant-input-affix-wrapper:hover {
  border-color: $fields-theme-color-5;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: $fields-theme-color-5;
  -webkit-box-shadow: 0 0 0 2px $field-box-shadow-color;
  box-shadow: 0 0 0 2px $field-box-shadow-color;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: $fields-theme-color-5;
}

// Select
.ant-select-focused {
  &.ant-select-single {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-color: $fields-theme-color-5;
        -webkit-box-shadow: 0 0 0 2px $field-box-shadow-color;
        box-shadow: 0 0 0 2px $field-box-shadow-color;
      }
    }
  }
  &.ant-select-multiple {
    .ant-select-selector {
      border-color: $fields-theme-color-5;
      -webkit-box-shadow: 0 0 0 2px $field-box-shadow-color;
      box-shadow: 0 0 0 2px $field-box-shadow-color;
    }
  }
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: $fields-theme-color-5;
  -webkit-box-shadow: 0 0 0 2px $field-box-shadow-color;
  box-shadow: 0 0 0 2px $field-box-shadow-color;
}

.ant-select {
  font-size: $base-font-size;
  &:not(.ant-select-disabled) {
    &:hover {
      .ant-select-selector {
        border-color: $fields-theme-color-5;
      }
    }
  }
}
.ant-select-item-option-selected {
  &:not(.ant-select-item-option-disabled) {
    background-color: $fields-theme-color-1;
    .ant-select-item-option-state {
      color: $fields-theme-color-6;
    }
  }
}
.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  color: $field-disabled-color;
}
.ant-form-item-has-warning
  .ant-select:not(.ant-select-borderless)
  .ant-select:not(.ant-select-disabled)
  .ant-select-selector {
  background-color: rgba($warning, 0.25);
  border-color: $warning !important;
}

// Datepicker
.ant-picker-panel-focused {
  border-color: $fields-theme-color-6;
}
.ant-picker-header-view button:hover {
  color: $fields-theme-color-6;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: $fields-theme-color-6;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: $fields-theme-color-1;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: $fields-theme-color-6;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: $fields-theme-color-1;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-top: 1px dashed $fields-theme-color-4;
  border-bottom: 1px dashed $fields-theme-color-4;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: $fields-theme-color-2;
}
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background: $fields-theme-color-2;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  border-left: 1px dashed $fields-theme-color-4;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-right: 1px dashed $fields-theme-color-4;
}
.ant-picker-today-btn {
  color: $fields-theme-color-6;
}
.ant-picker-today-btn:hover {
  color: $fields-theme-color-5;
}
.ant-picker-today-btn:active {
  color: $fields-theme-color-7;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  border-left: 1px dashed $fields-theme-color-4;
}
.ant-picker-panel-rtl
  .ant-picker-year-panel
  .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl
  .ant-picker-quarter-panel
  .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl
  .ant-picker-month-panel
  .ant-picker-cell-range-hover-start::after {
  border-right: 1px dashed $fields-theme-color-4;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  border-right: 1px dashed $fields-theme-color-4;
}
.ant-picker-panel-rtl
  .ant-picker-year-panel
  .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl
  .ant-picker-quarter-panel
  .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl
  .ant-picker-month-panel
  .ant-picker-cell-range-hover-end::after {
  border-left: 1px dashed $fields-theme-color-4;
}
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: $fields-theme-color-6;
}
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: $fields-theme-color-1;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: $fields-theme-color-5;
}
.ant-picker-focused {
  border-color: $fields-theme-color-5;
  -webkit-box-shadow: 0 0 0 2px $field-box-shadow-color;
  box-shadow: 0 0 0 2px $field-box-shadow-color;
}
.ant-picker-input > input {
  &:hover {
    border-color: $fields-theme-color-5;
  }
}
.ant-picker-range .ant-picker-active-bar {
  background: $fields-theme-color-6;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: $fields-theme-color-6;
  background: $fields-theme-color-1;
  border-color: $fields-theme-color-3;
}
.ant-picker-panel-rtl
  tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
.ant-picker-panel-rtl
  tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-panel-rtl
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-panel-rtl
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  border-right: 1px dashed $fields-theme-color-4;
}
.ant-picker-panel-rtl
  tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
.ant-picker-panel-rtl
  tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-panel-rtl
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-left: 1px dashed $fields-theme-color-4;
}
.ant-picker-panel-rtl
  tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-right: 1px dashed $fields-theme-color-4;
}
.ant-picker-panel-rtl
  tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after {
  border-left: 1px dashed $fields-theme-color-4;
}

// Radio
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $fields-theme-color-6;
}
.ant-radio-input:focus + .ant-radio-inner {
  -webkit-box-shadow: 0 0 0 3px $field-box-shadow-color-light;
  box-shadow: 0 0 0 3px $field-box-shadow-color-light;
}
.ant-radio-checked::after {
  border: 1px solid $fields-theme-color-6;
}
.ant-radio-inner::after {
  background-color: $fields-theme-color-6;
}
.ant-radio-checked .ant-radio-inner {
  border-color: $fields-theme-color-6;
}
.ant-radio-button-wrapper:hover {
  color: $fields-theme-color-6;
}
.ant-radio-button-wrapper:focus-within {
  -webkit-box-shadow: 0 0 0 3px $field-box-shadow-color-light;
  box-shadow: 0 0 0 3px $field-box-shadow-color-light;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: $fields-theme-color-6;
  border-color: $fields-theme-color-6;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: $fields-theme-color-6;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: $fields-theme-color-6;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: $fields-theme-color-5;
  border-color: $fields-theme-color-5;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: $fields-theme-color-5;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: $fields-theme-color-7;
  border-color: $fields-theme-color-7;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: $fields-theme-color-7;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  -webkit-box-shadow: 0 0 0 3px $field-box-shadow-color-light;
  box-shadow: 0 0 0 3px $field-box-shadow-color-light;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: $fields-theme-color-6;
  border-color: $fields-theme-color-6;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: $fields-theme-color-5;
  border-color: $fields-theme-color-5;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  background: $fields-theme-color-7;
  border-color: $fields-theme-color-7;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  -webkit-box-shadow: 0 0 0 3px $field-box-shadow-color-light;
  box-shadow: 0 0 0 3px $field-box-shadow-color-light;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: $fields-theme-color-5;
}

// checkbox
.ant-checkbox-wrapper {
  display: flex;
  align-items: baseline;
}
.ant-checkbox-wrapper .ant-checkbox {
  top: 2px;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $fields-theme-color-6;
}
.ant-checkbox-checked::after {
  border-color: $fields-theme-color-6;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $fields-theme-color-6;
  border-color: $fields-theme-color-6;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: $disabled-bg-color;
  border-color: $default-input-border-color;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $fields-theme-color-6;
}

// BTN
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: $fields-theme-color-5;
  border-color: $fields-theme-color-5;
}

.ant-upload {
  .ant-btn {
    &:hover,
    &:focus {
      color: $fields-theme-color-5;
      border-color: $fields-theme-color-5;
    }
  }
}

.ant-form-large {
  &.ant-form {
    label {
      font-size: $form-large-font-size;
    }
  }
  .ant-form-item-label {
    > label {
      font-size: $form-large-font-size;
    }
  }
}

.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
}
