@import 'styles/base';

.LitterDetailSummary {
  background-color: $white;
  border-radius: $border-radius;
  padding: 16px;
  margin-bottom: 16px;

  .LitterDetailSummary__info-parents-name {
    font-family: $primary-font-family;
    color: $black;
    font-size: 18px;
    font-weight: bold;

    .ant-col {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      p {
        width: 100%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;

        @media #{$max-768} {
          line-height: 1.85;
        }

        .anticon {
          margin-right: 6px;
        }
      }
    }
  }

  .changingParentsAlert {
    width: 256px;
    text-align: center;
    font-size: 15px;
    margin-top: 16px;
    > span {
      color: greyScale(0.65);
    }
  }
}

.LitterDetailSummary__parent-link {
  color: greyScale(0.85);
  display: block;
  &:hover {
    opacity: 0.85;
  }
}

.parentLinkContainer {
  > .ant-btn-link {
    color: $orange-6;
    display: table;
    margin: 0 auto;

    &:hover {
      color: $orange-5;
    }
  }
}
