@import 'styles/base';

.entityCard {
  background-color: $white;
  margin: 0 2px 16px;
  border-radius: 4px;
  border: 1px solid $white;
  &--warning {
    border-color: $warning;
  }
  &--alert {
    border-color: $error;
  }
  &--locked {
    opacity: 0.65;
  }

  &--page-detail {
    .entityCard__info-content {
      @media #{$min-576} {
        line-height: 2;
      }

      &--mobile {
        margin: 15px 0 0 0;
      }
    }
  }
}

.entityCard__info {
  padding: 10px;
  display: block;
  color: greyScale(0.85);
  &:hover,
  &:active {
    color: greyScale(0.85);
  }
}

.entityCard__info-img {
  position: relative;
  .EntityPhoto__image {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.entityCard__info-img-icon {
  background-color: $white;
  border-radius: 100%;
  position: absolute;
  top: 2px;
  right: -10px;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  z-index: 8;
  box-shadow: 0 2px 4px 0 greyScale(0.15);
}

.entityCard__info-img-footer {
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  background: greyScale(0.1);
  font-size: 13px;
  text-align: center;
  height: 25px;
  line-height: 25px;
}

.entityCard__info-content {
  .entityCard__info-title-container {
    display: flex;
    align-items: center;
  }
  .entityCard__info-title {
    font-family: $primary-font-family;
    color: greyScale(0.85);
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 0 5px;
    margin-left: 5px;

    > span {
      margin-right: 3px;
    }
  }
  p {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    line-height: 1.2;
    font-size: 16px;
    .anticon {
      margin-right: 6px;
      margin-left: 5px;
    }
  }
}

.entityCard__link {
  font-weight: 600;

  &.disableLink {
    pointer-events: none;
    opacity: 0.8;
    color: $black;
  }
}

.entityCard__info-content-pending-label {
  background: $blue-1;
  border-radius: $border-radius;
  display: inline-flex;
  font-size: 14px;
  margin-left: 5px;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
}

.entityCard__footer {
  border-top: 1px solid greyScale(0.08);
  text-align: center;

  .entityCard__footer__btn {
    height: 38px;
    &--alert,
    &--unlist {
      color: $red;
    }
    &--relist {
      color: $orange-6;
    }
    &--pendingsale {
      color: revert;
    }
    &:hover {
      filter: brightness(1.2);
    }
  }
}

.entityCard__action-retired {
  font-size: 14px;
  border: 1px solid $red;
  padding: 0 12px;
  border-radius: 4px;
  font-weight: bold;
  height: 24px;
  color: greyScale(0.65);
  display: inline-block;
  line-height: 22px;
  &:hover {
    cursor: pointer;
  }
}

/**
 * Entities Card variables
 */

.entityCard--litter {
  .entityCard__info-img {
  }
  .entityCard__info-content {
    line-height: 2.25;
  }
}

.PageLayout__MyListings,
.PageLayout__MyUpdates {
  .entityCard__info-img {
    margin: 15px 0 0 15px;
  }
  .entityCard__info-content {
    margin: 15px 15px 0 15px;
  }
  .entityCard__info-details {
    margin: 15px;
    .entityCard__info-content {
      margin: 0;

      p {
        width: 93%;
      }
    }
  }
}
