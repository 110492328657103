@import 'styles/base';

.FieldExistingParent {
  .ant-form-item-extra {
    color: greyScale(0.85);;
    margin-top: 8px;
    > span {
      > span[aria-label="info-circle"] {
        margin-left: 8px;
      }
    }
  }
}