@import 'styles/base';

.Modal__confirm--success {
  &.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
  &.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
    color: $green-6;
  }
}

.ant-modal-confirm .ant-modal-confirm-btns a + a {
  margin-bottom: 0;
  margin-left: 8px;
}

.ant-modal-body {
  p {
    margin-bottom: 3px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
