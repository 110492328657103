@import 'styles/base';

.register {
  padding-top: 20px;

  h1 {
    height: 48px;
    width: 338px;
    color: greyScale(0.85);
    font-size: 46px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 48px;
    margin-bottom: 10px;
    margin-top: 50px;
  }

  h2 {
    height: 56px;
    color: greyScale(0.65);
    font-size: 20px;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 15px;
    width: 100%;
  }

  ol {
    list-style: none;
    counter-reset: register-counter;
  }

  ol li {
    counter-increment: register-counter;

    h3 {
      display: inline;
      margin-left: 5px;
      font-size: $base-font-size;
      color: $font-color-dark-1;
    }

    p {
      color: $base-font-color;
      margin-left: -2px;
      line-height: 24px;
    }
  }

  ol li::before {
    font-size: 14px;
    content: counter(register-counter);
    color: black;
    background: $yellow;
    padding: 3px 10px 6px;
    border-radius: 50%;
    margin-left: -40px;
    margin-right: 5px;
  }

  .Collapsable {
    border-color: $yellow;
    border-bottom: 1px solid $yellow;
    .ant-collapse-item {
      border-bottom: none;
    }

    &.ant-collapse {
      background-color: initial;
    }

    .CollapsableExpandIcon {
      display: none;
    }

    .ant-collapse-header {
      pointer-events: none;
    }
  }

  @media #{$min-1200} {
    .Authorization__footer {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        margin: 0 10px;
      }
    }
  }
}
