@import 'styles/base';

.Navigation__ps-logo {
  position: relative;
  padding: 0 0.9rem;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s;

  a {
    display: flex;
    align-items: center;
    height: 64px;
  }

  .Navigation__ps-logo-text {
    margin: 0.3rem 0.3rem 0;
    width: 120px;
    animation: fadein;
    animation-duration: 0.3s;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
