@import 'styles/base';

.PageLayout__Transactions .PageLayout__content {
  padding: 0;

  .section-loading {
    @media #{$min-1200} {
      padding-top: 38px;
    }
  }
}

.Transaction__toolbar {
  padding: 20px 20px;
  background-color: $background;
  .ant-select {
    margin-right: 8px;
  }
}

.Transaction__toolbar__select-month {
  &.ant-select {
    width: 120px;
  }
}

.Transaction__toolbar__download {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  a {
    color: $blue-2
  }

  a:hover {
    color: $blue-1
  }
}

@media #{$max-600} {
  .Transaction__toolbar {
    flex-wrap: wrap;
  }
  .Transaction__toolbar__download {
    flex-basis: 250px;
  }
  .Transaction__toolbar__btn-container {
    margin-top: 12px;
    display: flex;
    flex: 1;
    align-items: center;
  }
}
