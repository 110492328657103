@import 'styles/base';

.BreederImpersonateHeader {
  background-color: $breeder-admin-impersonate-primary-color;
  &.ant-layout-header {
    padding: 8px 5px;
  }

  .ant-space {
    width: 100%;
    justify-content: flex-end;
  }

  .ant-btn {
    font-size: 12px;
  }

  .ant-btn-default {
    background: transparent;
    border-color: greyScale(0.65);
    color: greyScale(0.65);
    &:hover,
    &:focus {
      background: greyScale(0.05);
      color: greyScale(0.65);
      border-color: greyScale(0.65);
    }
  }

  .ant-btn-text {
    &:hover,
    &:focus {
      color: greyScale(0.5);
      background: transparent;
    }
  }
}

.BreederImpersonateHeader__logged-label {
  font-size: 14px;
  margin-left: 5px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
