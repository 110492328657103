@import 'styles/base';

.RecoveryPassword {
  padding: 0 20px;
  margin: 50px 0 149px;
  @media #{$min-1200} {
    margin-bottom: 30px;
  }
}

.RecoveryPage .ant-form-item-label,
.ResetPage .ant-form-item-label {
  display: none;
}

.RecoveryPassword .ant-alert-error {
  width: fit-content;
  margin-bottom: 10px;
}

.RecoveryPassword__submit-wrap {
  margin-bottom: 5px;
}

.RecoveryPassword__submit {
  background: $orange-6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: $base-font-size;

  &.ant-btn-primary:hover {
    background: $orange-6;
  }

  &.ant-btn {
    height: 40px;
    margin: 16px 0;

    span {
      display: block;
      margin-top: -1px;
    }
  }

  .ant-form-item-control {
    @media #{$min-1200} {
      max-width: initial;
    }
  }
}
