@import 'styles/base';

.PuppyPerformance {
  background: $white;
  margin-top: 16px;

  @media #{$min-1200} {
    margin-left: 16px;
    margin-top: 0;
  }
}
.PuppyPerformance__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 23px 16px;
  border-bottom: 1px solid greyScale(0.15);

  h4 {
    color: $font-color-dark-1;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }

  a {
    color: $base-font-color;
    font-size: 14px;
    font-weight: bold;
  }
}

.PuppyPerformance__content {
  ul {
    list-style: none;
    padding: 19px 16px;
    min-height: 443px;
    max-height: 443px;
    margin: 0;
  }
}

.PuppyPerformance__card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    .PuppyPerformance__card-indicator {
      background-color: lighten($black, 95);
    }
  }
}
.PuppyPerformance__card-indicator {
  color: greyScale(0.45);
  border: 1px solid lighten($black, 85);
  border-radius: 100%;
  text-align: center;
  font-size: 12px;
  width: 25px;
  height: 25px;
  display: inline-block;
  line-height: 22px;
  margin-right: 8px;
}

.PuppyPerformance__card-title {
  color: $black;
  font-size: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 115px);
  padding: 0 5px;
}

.PuppyPerformance__item {
  margin-bottom: 19px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.PuppyPerformance__card-view-counter {
  color: greyScale(0.65);
  font-size: 14px;
  text-align: right;
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .anticon {
    margin-right: 5px;
  }
}
