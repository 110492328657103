@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,300;0,600;1,300;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,600;1,300;1,600&display=swap');
@import './functions';

$primary-font-family: 'Sarabun', sans-serif;
$secondary-font-family: 'Nunito', sans-serif;
$base-font-size: 16px;
$base-font-color: greyScale(0.65);
$font-color-dark-1: greyScale(0.85);
$font-weight-normal: 300;
$font-weight-bold: 600;
