@import 'styles/base';

.EntityPhoto {
  position: relative;
  overflow: hidden;
  border-radius: $border-radius;
}

.EntityPhoto__image {
  background-size: cover;
  background-position: center;
  background-color: $lighter-gray;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.EntityPhoto__image--loading {
  font-size: 12px;
  color: greyScale(0.65);
}

.EntityPhoto__label {
  background: greyScale(0.1);
  font-size: 13px;
  text-align: center;
  height: 25px;
  line-height: 25px;
}