@import "styles/base";

@mixin media-uploader {
  display: flex;
  align-items: center;
  border: none;
  height: auto;
  margin-bottom: 8px;
  padding: 0;
  width: 100%;
}

.FieldMediaUploader {
  .ant-upload.ant-upload-select {display: block;}
}

.MediaUploader__indicator {
  background-color: $white;
  border: $default-input-border-color 1px solid;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-right: 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 29px;
  text-align: center;
}
.MediaUploader__indicator--loading {
  border: none;
}
.MediaUploader__content {
  background-color: $white;
  display: flex;
  align-items: center;
  border: $default-input-border-color 1px solid;
  border-radius: 5px;
  overflow: hidden;
  flex: 1;
}
.MediaUploader__content-thumb {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  border-right: $default-input-border-color 1px solid;
  background-color: darken($white, 7);
  color: greyScale(0.25);
  height: 70px;
  width: 70px;
}
.MediaUploader__content-text {
  padding: 10px;
  width: 78%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  > div {
    display: flex;
    flex-direction: column;
    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.MediaUploader__tools-delete {
  &.ant-btn {
    color: greyScale(0.4);
    padding: 6px;
    border: none;
    &:hover {
      color: $error;
    }
  }
}

.MediaUploader__tools-upload {
  &.ant-btn {
    color: greyScale(0.4);
    padding-left: 6px;
    border: none;
  }
}

.MediaUploader--btn {
  &.ant-btn {
    @include  media-uploader;
    &:hover,
    &:focus {
      color: $color-primary-brightness;
      .MediaUploader__indicator {
        border-color: $color-primary-brightness;
      }
      .MediaUploader__content {
        border-color: $color-primary-brightness;
      }
    }
    &[disabled] {
      opacity: 0.75;
      &:hover {
        color: rgba(0, 0, 0, 0.25);
        .MediaUploader__content,
        .MediaUploader__indicator{
          border-color: $default-input-border-color;
        }
      }
    }
  }

  .MediaUploader__content {
    border-style: dashed;
    border-width: 2px;
  }
}

.ant-upload.ant-upload-disabled {
  .MediaUploader--btn {
    opacity: 0.8;
    &.ant-btn {
      color: greyScale(0.4);
      cursor: not-allowed;
      &:hover,
      &:focus {
        color: greyScale(0.4);
        .MediaUploader__indicator {
          border-color: $default-input-border-color;
        }
        .MediaUploader__content {
          border-color: $default-input-border-color;
        }
      }
    }
  }
}


.MediaUploader--item {
  .ant-btn {
    padding: 0;
    margin-left: 24px;
  }
  @include  media-uploader;
  &[role=button] {
    outline: none;
    box-shadow: none;
  }
}

.MediaUploader--item--video {
  .MediaUploader__content-thumb {
    cursor: pointer;
    background-color: $black;
    color: rgba($white, 0.8);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 'center center';
  }
}
.MediaUploader--item--photo {
  .MediaUploader__content-thumb {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.MediaUploader--item--file {
  .MediaUploader__content {
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      color: $color-primary;
      border-color: $color-primary;
    }
  }
}

.previewVideo {
  background-color: $black;
  color: rgba($white, 0.6);
  font-size: 35px;
  height: 265.5px;
  width: 472px;
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    height: 100%;
    width: 100%;
  }
}

.FieldPuppyPhotosUpload {
  margin-bottom: 0;
  .ant-form-item-control-input {
    min-height: auto;
  }
}

.MediaUploader__replaceVideo {
  margin: 0 auto;
  display: block;
}