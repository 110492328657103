@import 'styles/base';

.UserDropDown {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.UserDropDown__name-wrap {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.UserDropDown__email-initial {
  background: $blue-1;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  border-radius: 50%;
  text-transform: capitalize;
  font-size: 15px;
  width: 24px;
  height: 24px;

  @media #{$min-1200} {
    margin-right: 5px;
  }
}

.ant-layout-header .UserDropDown__email {
  color: greyScale(0.65);
}

.UserDropDown__email {
  display: none;
  font-weight: $font-weight-normal;
  @media #{$min-1200} {
    display: inline-block;
  }
}

.BaseLayoutHeader__call-us-wrap {
  color: $base-font-color;
}

.BaseLayoutHeader__call-us {
  display: none;
  @media #{$min-1200} {
    display: inline-block;
    margin-left: 5px;
  }
}

.HeaderDropDown__Item {
  font-size: 16px;
}
