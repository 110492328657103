@import "styles/base";

body.new-puppy,
body.new-listing {
  .PageLayout__content {
    overflow-y: initial;
  }
}

.NewListing__spinner {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.NewListing__spinner-ic svg{
  width: 30px;
  height: 30px;
}