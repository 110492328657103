@import 'styles/base';

.BaseLayoutAside {
  position: fixed;
  z-index: $z-index-aside;
  height: 100%;
  top: 0;
  left: 0;

  &.ant-layout-sider-light,
  .ant-drawer-content {
    background-color: $blue-1;
    box-shadow: 0 0 4px $blue-1;
  }
}

.BaseLayout__container {
  transition: padding-left, 0.2s;
  position: relative;

  &--collapsed {
    padding-left: 80px;
  }

  &--uncollapsed {
    padding-left: 300px;
  }

  @media #{$max-1200} {
    padding-left: 0;
  }
}

/**
 * Theme Breeder Admin impersonate
 */

.BaseLayout--breeder-impersonate-mode {
  .UserDropDown__email-initial {
    background-color: $breeder-admin-impersonate-primary-color;
  }
}
