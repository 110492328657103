@import 'styles/base';

.PuppyInventory {
  background: $white;
}

.PuppyInventory__header,
.PuppyInventory__content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media #{$min-768} {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.PuppyInventory__header {
  padding: 23px 16px;
  border-bottom: 1px solid greyScale(0.15);

  h4 {
    color: $font-color-dark-1;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }

  a {
    color: $base-font-color;
    font-size: 14px;
    font-weight: bold;
  }
}

.PuppyInventory__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0;
  min-width: 116px;

  @media #{$min-768} {
    width: 20%;
  }

  @media #{$min-1200} {
    justify-content: center;
    flex-direction: row;
    border-right: 1px solid greyScale(0.15);
  }

  &:last-of-type {
    border: 0;
  }

  span {
    color: $font-color-dark-1;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    display: block;
    text-transform: capitalize;
  }
}

.PuppyInventory__circle {
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid transparent;
  border-radius: 100%;
  color: $font-color-dark-1;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.PuppyInventory__circle--yellow {
  border-color: $orange-4;
}

.PuppyInventory__circle--green {
  border-color: $green-5;
}

.PuppyInventory__circle--blue {
  border-color: $blue-2;
}

.PuppyInventory__circle--orange {
  border-color: $orange-6;
}

.PuppyInventory__circle--gray {
  border-color: rgba(0, 0, 0, 0.45);
}
