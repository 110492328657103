@import 'styles/base';

.UpcomingUpdates {
  &__link,
  &__link:hover {
    color: $base-font-color;
    display: block;
  }
  &__container {
    @media #{$min-768} {
      .border {
        &:first-child {
          border-right: 1px solid greyScale(0.15);
        }
        &:nth-child(3) {
          border-top: 1px solid greyScale(0.15);
          border-right: 1px solid greyScale(0.15);
        }
        &:nth-child(4) {
          border-top: 1px solid greyScale(0.15);
        }
      }
    }
  }
}
