@import 'styles/base';

.PuppyTravel_header {
  text-align: center;
  padding: 20px 0 16px;
  h2 {
    font-weight: bold;
  }
}

.PuppyTravel_info {
  padding: 16px;
  border-radius: $border-radius;
  box-shadow: 0 1px 4px greyScale(0.2);
  margin-bottom: 16px;
}

.PuppyTravelInfoItem {
  border-bottom: 1px solid greyScale(0.15);
  padding: 10px 0;
  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
}
.PuppyTravel_info-title {
  border-bottom: 1px dashed greyScale(0.15);
  margin: 0;
  line-height: 2.5;
}
.PuppyTravelInfoItem__label {
  margin: 0;
  line-height: 1.25;
}
.PuppyTravelInfoItem__value {
  margin: 0;
  b {
    padding-right: 7px;
  }
}

.PuppyTravel_collapse {
  margin-bottom: 28px;
  border: none;
  &.ant-collapse {
    background-color: transparent;
  }

  .ant-collapse-item {
    margin-bottom: 20px;
    box-shadow: 0 0 4px greyScale(0.15);
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .ant-collapse-header {
    .sequence-count {
      font-size: 13px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      color: greyScale(0.65);
      background-color: transparent;
      border: 1px solid $blue-1;
      height: 23px;
      width: 23px;
      margin-right: 6px;
    }
  }
  .ant-collapse-content {
    border-top-style: dashed;
    border-top-width: 2px;
  }
}

.PuppyTravel__empty {
  text-align: center;

  >span {
    margin-top: 40px;
  }

  .PuppyTravel__empty-icon {
    color: rgba(0, 0, 0, 0.65);
    font-size: 50px;
  }

  .PuppyTravel__empty-badge {
    font-size: 13px;
    padding: 5px;
    top: 9px;
    right: 2px;
    border-radius: 50%;
    background-color: #71d0ff;
  }

  .PuppyTravel__empty-btn {
    margin: 64px 0;
  }
}

.PuppyTravel_body-proposal {
  box-shadow: 0 0 4px greyScale(0.15);
}

.PuppyTravel_title {
  padding: 12px 16px;
  border: 1px solid $light-gray;
  border-bottom: 0;
  h4 {
    margin: 0;
    color: rgba(0, 0, 0, 0.65);
  }
}

.PuppyTravel_proposal {
  padding: 16px;
  border: 1px solid $light-gray;
  border-top-style: dashed;
  border-top-width: 2px;
  margin-bottom: 28px;
}