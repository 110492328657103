.SubmitModal {
  .ant-modal-header,
  .ant-modal-footer {
    border: none;
  }
  .ant-modal-footer {
    padding-bottom: 20px;
  }
  .ant-modal-body {
    padding: 15px 24px;
  }
}
.SubmitModal--hide-buttons {
  .ant-btn-default,
  .ant-btn-primary {
    display: none;
  }
}
