@import 'styles/base';

.entity-columns-small__tabs {
  .ant-tabs-nav-list {
    width: 100%;
    border-bottom: 1px solid greyScale(0.1);
    position: relative;
    display: flex;
    transition: transform 0.3s;
  }

  .ant-tabs-nav-operations-hidden {
    position: absolute;
    visibility: hidden;
    pointer-events: none;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-tab {
    flex: 1;
    justify-content: center;
    margin: 0 15px;
    font-size: $base-font-size;

    .ant-badge-count {
      background: transparent;
      color: greyScale(0.85);
      box-shadow: 0 0 0 1px greyScale(0.85);
    }
  }

  .ant-tabs-tab {
    color: $base-font-color;
    > div {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $font-color-dark-1;
  }

  .ant-tabs-nav-wrap {
    background-color: $background;
  }

  .ant-tabs-ink-bar {
    background: greyScale(0.5);
    height: 2px;
    bottom: 0;
  }
  .ant-tabs-ink-bar-animated {
    transition: width 0.3s, left 0.3s, right 0.3s;
  }
}
.entity-columns-small__tabs-name {
  display: block;
  font-size: 14px;
}

.entity-columns-small__tab-column {
  padding: 15px;
}

.entity-columns-small .ant-tabs {
  color: $font-color-dark-1;
  font-size: $base-font-size;
}

.entity-columns-small__tab-column .entity-card {
  margin: 10px 0;
}
