@import 'styles/functions';
/* green base colors */
$green-1: #e9f0e1;
$green-2: #dce3d5;
$green-3: #bfd6a9;
$green-4: #a0c97f;
$green-5: #82bd59;
$green-6: #66af37;
$green-7: #478a24;
$green-8: #2e6315;
$green-9: #183d0a;
$green-10: #081703;

/* orange base colors */
$orange-1: #fff8e6;
$orange-2: #ffe3a3;
$orange-3: #ffd37a;
$orange-4: #ffbf52;
$orange-5: #ffbf52;
$orange-6: #ff9100;
$orange-7: #d97400;
$orange-8: #b35900;
$orange-9: #8c4100;
$orange-10: #662c00;

$blue-1: #ace5fc;
$blue-2: #40a9ff;
$blue-3: #71d0ff;
$pink-1: #ffbbbb;

$yellow: #ffd300;

$black: #000000;
$white: #ffffff;
$red: #ff7272;
$yellow: #ffd300;

$light-gray: #d9d9d9;
$lighter-gray: #e2e2e2;
$lightest-gray: #f6f6f6;

/* miscelaneous */
$color-primary: $orange-6;
$color-primary-brightness: #ffb00d;
$color-secondary: #30788b;
$color-secondary-light: #7bd1d6;
$background: #f0f2f5;
$primary-link: $color-secondary;
$error: #ff4d4f;
$success: $green-5;
$warning: #ffd300;
$border-ant-table-row: #e8e8e8;
$ant-table-header: #fafafa;
$blue-pb: #8cbbf0;
$default-input-border-color: $light-gray;
$disabled-bg-color: #f5f5f5;

$breeder-admin-impersonate-primary-color: #bdffc0;
