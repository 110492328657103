@import 'styles/base';

.PuppyForm {
  .border-right-lighter-gray {
    border-right: none;
    @media #{$min-1200} {
      border-right: 1px solid greyScale(0.05);
    }
  }

  .field-puppy-cost {
    .ant-input-number-handler-wrap {
      display: none;
    }
    .ant-input-number-input {
      text-align: right;
    }
  }

  .field-item-inline {
    flex-direction: inherit;
    flex-wrap: inherit;

    @media #{$max-600} {
      .ant-form-item-label {
        flex: 0 0 50%;
        max-width: 50%;
      }
      .ant-form-item-control {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }

  .ant-form-item-label {
    .anticon {
      margin-left: 5px;
    }
  }
}

.PuppyForm__column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.PuppyForm__column-bottom {
  padding: 16px;
  border: 1px solid greyScale(0.15);

  .ant-form-item-explain.ant-form-item-explain-error {
    margin-left: 24px;
  }
  @media #{$min-1200} {
    margin-bottom: 30px;
  }
}

.PuppyForm__litterItem {
  display: block;
}

.PuppyForm__litter {
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  margin-top: -8px;
}

.PuppyForm__litter--btn {
  line-height: 1;
  font-size: 15px;
  height: auto;
  padding: 3px 13px 5px;
  margin-right: 40px;
}

.PuppyCost__popover {
  width: 280px;
  padding: 4px 0;
}
