@import 'styles/base';

.RegisterForm_submit {
  background: $orange-6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  flex: 1;
  font-size: $base-font-size;
  border: none;

  &.ant-btn-primary:hover {
    background: $orange-6;
  }

  &.ant-btn {
    height: 40px;
    margin: 16px 0;

    span {
      display: block;
      margin-top: -1px;
    }
  }
}

.RegisterForm {
  a {
    color: $orange-6;
    font-weight: bolder;
  }
}

.RegisterForm__long-label {
  .ant-form-item-label > label {
    min-height: 53px;
  }
}