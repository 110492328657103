@import 'styles/base';

.DownloadHealthDocs {
  & > .anticon + span {
    color: $color-primary;
  }
  &:hover,
  &:focus,
  &:active {
    color: $color-primary;
  }
}

.entityCard__info-content {
  .DownloadHealthDocs {
    padding: 0;
  }
}
