@import 'styles/base';
@import 'styles/antFieldsOverride';
@import 'styles/antLayoutOverride';
@import 'styles/antBtnOverride';
@import 'styles/antModalOverrride';

body > #root {
  height: 100vh;
}

body > iframe {
  pointer-events: none;
}

body {
  background-color: $background;
  color: $base-font-color;
  font-family: $primary-font-family;
  font-weight: 400;
  font-size: $base-font-size;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $base-font-color;
  font-family: $secondary-font-family;
  font-weight: 400;
}

.ellipsis-text {
  width: 80%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-alert {
  color: $error;
}

.text-warning {
  color: $warning;
}

.text-success {
  color: $green-6;
}
.cursor-default {
  cursor: default;
}
.cursor-not-allowed {
  cursor: not-allowed;
}

.display-none {
  display: none;
}

.display-only-mobile {
  @media #{$min-1200} {
    display: none;
  }
}

a {
  color: $color-primary;
  &:hover {
    color: $color-primary;
  }
}

.ant-image {
  border-radius: $border-radius;
}
.ant-image-img {
  width: 100%;
  height: auto;
  border-radius: $border-radius;
}
.ant-image-error {
  background-color: $lighter-gray;
}

.border-right-lighter-gray {
  border-right: 1px solid greyScale(0.05);
}

.ant-tooltip {
  z-index: 999;
}

.LoadMore.ant-btn {
  color: $orange-6;
  text-align: center;
  line-height: 13px;
  width: 100%;
  min-height: 30px;
  height: 30px;
}

.LoadMore.ant-btn:before {
  background: initial;
}

.recommended-price {
  margin-top: 35px;
  margin-bottom: 0;
}

.ant-form-item-with-help.help-above {
  .ant-form-item-explain {
    color: #333;
  }

  .ant-form-item-label {
    label {
      height: 25px;
      padding: 0;
    }
  }

  .ant-form-item-control {
    display: flex;
    flex-direction: column-reverse;
  }
}

@import 'styles/myListings';
