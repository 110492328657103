@import 'styles/base';

.ant-btn {
  &.unlisted-relisted-btn {
    text-transform: capitalize;
    &:hover {
      filter: brightness(1.3);
    }
    &[disabled],
    &[disabled]:hover {
      color: greyScale(0.25);
      filter: none;
    }
  }
  &.unlisted-relisted-btn--relisted {
    color: $color-primary;
  }
  &.unlisted-relisted-btn--unlisted {
    color: $red;
  }
}

.unlisted-relisted-modal {
  &--unlisted {
    .ant-modal-confirm-content {
      .unlisted-relisted-modal__list {
        margin-top: 0.5rem;
        margin-bottom: 0;
        padding-left: 1.85rem;
      }
    }
    @media #{$min-480} {
      width: 480px !important;
    }
    @media #{$max-480} {
      .ant-modal-body {
        padding: 20px 13px 15px;
      }
      .ant-modal-confirm-body > .anticon {
        margin-right: 8px;
      }
      .ant-modal-confirm-body
        > .anticon
        + .ant-modal-confirm-title
        + .ant-modal-confirm-content {
        margin-left: 0.313rem;
      }

      .ant-modal-confirm-content {
        .unlisted-relisted-modal__list {
          padding-left: 1.5rem;
        }
      }

      .ant-modal-confirm-btns {
        margin-top: 1rem;
      }
    }
  }

  &--relisted {
    @media #{$max-480} {
      .ant-modal-body {
        padding: 32px 13px 24px;
      }
    }
  }
}
