@import 'styles/base';

.ResetForm {
  padding: 0 20px;
  margin: 50px 0 149px;
  @media #{$min-1200} {
    margin-bottom: 30px;
  }
}

.ResetForm .ant-alert-error {
  width: fit-content;
  margin-bottom: 10px;
}

.ResetForm__submit-wrap {
  margin-bottom: 5px;
}

.ResetForm__submit {
  background: $orange-6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: $base-font-size;
  &.ant-btn-primary:hover {
    background: $orange-6;
  }

  &.ant-btn {
    height: 40px;
    margin: 16px 0;

    span {
      display: block;
      margin-top: -1px;
    }
  }
}

.ant-form-item-control {
  @media #{$min-1200} {
    max-width: initial;
  }
}

.ResetForm__password-input{
  margin-bottom: 10px;
}
