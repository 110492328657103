@import 'styles/base';

.PuppyDetailHealthDocs {
  .HealthDocsUploads__empty {
    padding: 5em 0;
  }
}

.PuppyDetailHealthDocs__header {
  margin-bottom: 1em;
}

.PuppyDetailHealthDocs__customer-name,
.PuppyDetailHealthDocs__travel-proposed {
  text-align: center;
  font-size: 1.15em;
  margin: 0;
}

.PuppyDetailHealthDocs__status-text {
  text-align: center;
}

.PuppyDetailHealthDocs__status-wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid greyScale(0.07);
  padding: 0.8em;
  align-items: center;
}

.PuppyDetailHealthDocs__status-wrapper__left {
  display: flex;

  .HealthDocBadge {
    margin-right: 0.8em;
  }
}
