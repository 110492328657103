@import "styles/base";

.CreateListingStarter__modal {
  .ant-modal-body {
    padding-bottom: 20px;
  }
  .ant-modal-footer {
    border: none;
    padding: 0 24px 24px;
  }
}
.CreateListingStarter__modal-header {
  display: flex;
  align-items: flex-start;
  padding: 10px 0 16px;
  .anticon {
    color: $warning ;
    margin-right: 13px;
    font-size: 22px;
  }
  .CreateListingStarterContent__modal-title {
    color: greyScale(0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
  }
}

.CreateListingStarter__modal-content {
  padding: 0 40px;
}
