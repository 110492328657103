@import 'styles/base';

.parentCard__info {
  padding: 12px;

  &-content {
    padding-left: 16px;
  }

  .ant-row {
    > .ant-col {
      color: greyScale(0.65);
    }
  }
}


