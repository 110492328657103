@import 'styles/base';

.remove-litter.ant-btn {
  font-size: 16px;
  padding: 0;
  font-weight: 400;
  &:hover,
  &:focus {
    color: $color-primary;
  }
  &[disabled] {
    &:hover {
      color: greyScale(0.25);
    }
  }
  &.ant-btn-loading {
    color: greyScale(0.25);
    &::before {
      background: transparent;
    }
  }
}
