@import 'styles/base';

.PuppyDetailOrderStatus {
  padding: 0.75em;
  margin-bottom: 1em;
}

.PuppyDetailOrderStatus__title {
  color: $black;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0.25em;
  text-align: center;
}

.PuppyDetailOrderStatus__description {
  font-size: 14px;
  margin: 0;
  text-align: center;
}
