@import 'styles/base';

.HealthDocDueDateTooltip__icon {
  padding: 0.25em;
  font-size: 1rem;
  color: greyScale(0.65);

  &:hover {
    color: $color-primary;
  }
}
