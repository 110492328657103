@import 'styles/base';

.MyListings--delivered {
  .MyListings__page-header {
    .ant-page-header-content {
      padding-top: 0;
    }

    .MyListings__page-header-content__subtitle {
      padding-left: 33px;
    }
  }
}
