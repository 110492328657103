@import 'styles/base';

.AddFamilyNavigation {
  display: flex;
  justify-content: flex-end;
  @media #{$max-1200} {
    background-color: $blue-1;
    border-top: 1px solid greyScale(0.1);
  }
  &__call-add-dams {
    margin-left: 10px;
  }
}
.AddFamilyNavigation__btn {
  &.ant-btn {
    background-color: transparent;
    margin-left: 16px;
    display: flex;
    > .anticon {
      font-size: 20px;
    }
    @media #{$max-1200} {
      display: inline-block;
      border: none;
      border-radius: 0;
      border-left: 1px solid greyScale(0.1);
      box-shadow: none;
      height: auto;
      margin: 0;
      width: 25%;
      line-height: 1;
      font-size: 24px;
      padding: 8px 15px 18px;
      > span {
        display: block;
        margin: 0;
      }

      > .anticon,
      .ant-btn-loading-icon {
        font-size: 24px;
        + span {
          margin: 0;
          font-size: 13px;
          font-weight: normal;
        }
      }
      .ant-btn-loading-icon {
        font-size: 20px;
        line-height: 33px;
      }
      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        color: greyScale(0.65);
        border-left: 1px solid greyScale(0.1);
      }

      &:first-of-type {
        border-left: none;
      }
    }
  }
}
