@import 'styles/base';

.HealthDocsUploads__empty {
  text-align: center;
  padding: 2.5em 0;

  .ant-typography {
    margin-bottom: 0;
  }
}

.HealthDocsUploads__list-item {
  margin-bottom: 1.8em;
}

.HealthDocsUploads__list-item__file {
  display: flex;
  align-items: center;
  margin-bottom: 0.8em;

  .HealthDocsUploads__list-item__file-link {
    color: greyScale(0.85);
    display: inline-flex;
    align-items: center;

    .HealthDocsUploads__list-item__file-name {
      color: greyScale(0.85);
      transition: color 0.3s;
    }
    &:hover {
      color: $color-primary;
      filter: brightness(1.2);
      .HealthDocsUploads__list-item__file-name {
        color: $color-primary;
      }
    }
  }

  .HealthDocsUploads__list-item__file-icon {
    font-size: 1.8em;
    margin-right: 0.15em;
  }

  .HealthDocsUploads__list-item__file-name {
    margin-right: 1em;
  }

  .HealthDocsUploads__list-item__file-uploadedAt {
  }
}

.HealthDocsUploads__list-item__file-documents {
  padding-left: 0.25em;

  .HealthDocBadge__fullInfo {
    margin-bottom: 0.8rem;
  }
}
