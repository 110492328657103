@import 'styles/base';

.Dashboard--empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 70vh;
  padding-bottom: 73px;
  @media #{$min-1200} {
    min-height: 90vh;
    padding-bottom: 0;
  }

  p {
    color: greyScale(0.65);
    font-size: 14px;
    margin-top: 18px;
    max-width: 300px;
    @media #{$min-1200} {
      max-width: 364px;
    }
  }

  svg {
    height: 100px;
    width: 239px;
    opacity: 0.45;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    max-width: 338px;
    padding: 16px 12px;
    margin-top: 53px;
    border: 2px dashed greyScale(0.25);
    border-radius: 2px;
    font-size: 20px;
    color: greyScale(0.65);
    @media #{$min-1200} {
      max-width: 352px;
    }
  }
}

.Dashboard__loading {
  text-align: center;
  padding-top: 25vh;
  .Dashboard__spinner-icon {
    font-size: 25px;
  }
}
