@import 'styles/base';
.login {
  @media #{$min-1200} {
    min-height: 800px;
  }
}

.LoginForm {
  padding: 0 20px;
}

.LoginForm .ant-col.ant-col-8.ant-form-item-label {
  display: none;
}

.LoginForm .FieldPassword .ant-form-item-extra {
  display: none;
}

.LoginForm .ant-alert-error {
  width: fit-content;
  margin-bottom: 10px;
}

.LoginForm__submit-wrap {
  margin-bottom: 5px;
}

[data-testid="submit-login"]{
  margin-bottom: 5px;
}

.LoginForm__submit {
  background: $orange-6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: $base-font-size;

  &.ant-btn-primary:hover {
    background: $orange-6;
  }

  &.ant-btn {
    height: 40px;
    margin: 16px 0;

    span {
      display: block;
      margin-top: -1px;
    }
  }
}

.ant-form-item-control {
  @media #{$min-1200} {
    max-width: initial;
  }
}

.LoginForm__register {
  margin: 0 0 30px;
}

.LoginForm__register.Login__margin {
  padding-top: 0;
  margin-top: 0;
}

.LoginForm__password-input {
  margin-bottom: 10px;
}

.LoginForm__forgot {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 35px;
  color: $orange-6;
  font-weight: bolder;
}

.Login__register {
  margin-top: 0;
  margin-bottom: 40px;

  a {
    color: $orange-6;
    font-weight: bolder;
  }
}

