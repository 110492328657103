@import 'styles/base';

.ErrorBoundary {
  text-align: center;
  margin-top: 10%;
}

.ErrorBoundary__title {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 20px;
}