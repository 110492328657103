@import 'styles/base';

.puppy-health-fee {
  .field-item-inline {
    margin-bottom: 0px;
  }
  &__value {
    padding-right: 10px;
    font-size: 16px;
    text-align: right;
    display: block;
    &--bold {
      font-weight: 600;
    }
  }
}

.health-fee-popover-content {
  width: 280px;
  p {
    margin: 0;
  }
}
