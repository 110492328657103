@import 'styles/base';

.UpToDateInfoBarButton {
  &.ant-btn {
    color: greyScale(0.65);
    border-radius: 0;
    width: 18%;
    height: 45px;
    background-color: transparent;
    border-color: transparent;
    border-left-color: $light-gray;

    &:hover {
      color: greyScale(0.3);
      border-left-color: $light-gray;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      color: greyScale(0.25);
      background-color: transparent;
      border-color: transparent;
      border-left-color: $light-gray;
    }

    &:after {
      animation: none;
    }
  }

  .ant-badge {
    .btn-icon {
      font-size: 15px;
    }

    .badge-icon {
      font-size: 13px;

      &--error {
        color: $error;
      }

      &--warning {
        color: $warning;
      }

      &--success {
        color: $success;
      }

      &--pending {
        color: $blue-3;
      }

      &.anticon-hourglass {
        color: $white;
        background-color: $blue-3;
        border-radius: 50%;
        padding: 3px;
        font-size: 9px;
      }
    }
  }
}
