@import 'styles/base';

$color-document-workflow-status-pending-review: #71d0ff;
$color-document-workflow-status-approved: #66af37;
$color-document-workflow-status-rejected: #ff7272;
$color-document-workflow-status-expired: #777777;
$color-document-workflow-status-awaiting-disclosure: #9747FF;

.HealthDocBadge {
  font-size: 12px;
  border: 1px solid greyScale(0.15);
  color: $base-font-color;
  background-color: $white;
  cursor: default;
}

.HealthDocBadge__shape--circle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.HealthDocBadge__shape--pill,
.HealthDocBadge__shape--fullInfo {
  padding: 0.1rem 1rem;
  text-align: center;
  border-radius: 25px;
  display: inline-block;
}

.HealthDocBadge__fullInfo-expiresAt {
  font-size: 0.98em;
  color: greyScale(0.45);
  padding-left: 0.35em;
  margin: 0.15em 0 0;
}

.HealthDocBadge--approved {
  border-color: $color-document-workflow-status-approved;
  color: $color-document-workflow-status-approved;
  background-color: rgba($color-document-workflow-status-approved, 0.07);
}

.HealthDocBadge--pending-review {
  border-color: $color-document-workflow-status-pending-review;
  color: $color-document-workflow-status-pending-review;
  background-color: rgba($color-document-workflow-status-pending-review, 0.07);
}

.HealthDocBadge--rejected {
  border-color: $color-document-workflow-status-rejected;
  color: $color-document-workflow-status-rejected;
  background-color: rgba($color-document-workflow-status-rejected, 0.07);
}

.HealthDocBadge--expired {
  border-color: $color-document-workflow-status-expired;
  color: $color-document-workflow-status-expired;
  background-color: rgba($color-document-workflow-status-expired, 0.07);
}

.HealthDocBadge--awaiting-disclosure {
  border-color: $color-document-workflow-status-awaiting-disclosure;
  color: $color-document-workflow-status-awaiting-disclosure;
  background-color: rgba($color-document-workflow-status-awaiting-disclosure, 0.07);
}

.ColumnHealthDoc {
  .HealthDocBadge {
    cursor: pointer;
  }
}
