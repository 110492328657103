.modal-buttons {
  display: flex;
  margin-top: 38px;

  button:first-of-type {
    margin-left: auto !important;
  }
}

.modal-title {
  color: rgba(0, 0, 0, 0.85);
  font-family: Sarabun;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 0;
}

.modal-content-price-update {
  > div:first-of-type {
    flex-direction: row;
  }

  .ant-form-item-label {
    padding-bottom: 0;
    align-self: center;
  }

  label {
    color: rgba(0, 0, 0, 0.85);
    font-family: Sarabun;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    
    span {
      margin-left: 5px;
    }
  }

  input {
    text-align-last: right;
  }

  .puppy-health-fee {
    > div {
      flex-direction: row;
    }
  }

  .recommended-price {
    margin-top: 20px;
  }
}

.ant-input-number-handler-wrap {
  display: none;
}