@import 'styles/base';

.PageLayout {
  padding: 0;
}

.PageLayout__header {
  &.ant-layout-header {
    background-color: $white;
    padding: 8px 16px;
    display: flex;
    width: 100%;
    align-items: center;

    @media #{$min-1200} {
      padding: 16px 32px;
      height: 56px;
    }
    > div {
      @media #{$min-1200} {
        width: 50%;
      }
    }
  }
}

.PageLayout__toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.PageLayout__content {
  padding: 16px 16px 100px;
  @media #{$min-1200} {
    padding: 10px;
  }
}

.PageLayout__title {
  color: $font-color-dark-1;
  font-size: 20px;
  margin: 8px 0 0;
}
