$font-small: 14px;
$font-default: 16px;

// Media Queries
$min-360: 'screen and (min-width: 360px)';
$min-480: 'screen and (min-width: 480px)';
$min-576: 'screen and (min-width: 576px)';
$min-768: 'screen and (min-width: 768px)';
$min-992: 'screen and (min-width: 992px)';
$min-1200: 'screen and (min-width: 1200px)';
$min-1600: 'screen and (min-width: 1600px)';

$max-480: 'screen and (max-width: 479px)';
$max-600: 'screen and (max-width: 575px)';
$max-768: 'screen and (max-width: 767px)';
$max-992: 'screen and (max-width: 991px)';
$max-1200: 'screen and (max-width: 1199px)';
$max-1600: 'screen and (max-width: 1599px)';

$z-index-aside: 15;
$z-index-header: 1;

$border-radius: 3px;
