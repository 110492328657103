@import 'styles/base';

.ant-btn {
  border-radius: 4px;
  color: $base-font-color;
  font-family: $primary-font-family;
  font-weight: $font-weight-bold;
}

.ant-btn-default, .ant-upload .ant-btn-default {
  border-color: $color-primary;
  color: $base-font-color;
  &:hover,
  &:focus {
    color: $color-primary;
    border-color: $color-primary;
    filter: brightness(1.2);
  }
  &[disabled] {
    color: greyScale(0.25); 
    border-color: $light-gray;
    &:hover,
    &:focus {
      filter: none;
    }
  }
}

.ant-btn-primary {
  background-color: $color-primary;
  border-color: $color-primary;
  &:hover,
  &:focus {
    background-color: $color-primary;
    border-color: $color-primary;
    color: $base-font-color;
    filter: brightness(1.2);
  }
  &[disabled] {
    &:hover,
    &:focus {
      filter: none;
    }
  }
}

.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
  margin-right: 8px;
}

.ant-btn-sm {
  height: 26px;
  padding: 0px 7px 2px;
}
