@import 'styles/base';

.updatePuppyDocs__modal {
  &__title {
    font-size: 16px;
    .anticon {
      padding-right: 5px;
    }
  }
  form {
    label {
      display: none;
    }
    button {
      &:first-child {
        margin-left: 0;
      }
      margin-left: 16px;
    }
  }
}
