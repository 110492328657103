@import 'styles/base';

.MarkLabel {
  &__info {
    background: greyScale(0.14);
    padding: 0 4px;
    color: $base-font-color;
    font-size: 16px;
    width: auto;

     svg {
      margin-right: 4px;
    }
  }
}