@import 'styles/base';

.EntityBadge {
  cursor: pointer;
  color: greyScale(0.65);
  box-shadow: 1px 1px 4px 0 greyScale(0.45);
  width: 25px;
  height: 25px;
  display: inline-flex;
  text-align: center;
  z-index: 1;
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: $white;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  &:hover {
    color: greyScale(0.85);
    color: $blue-3;
  }
}
