@import 'styles/base';

.MyListingsSearch {
  padding: 0.5em 0;
  background-color: $background;

  @media #{$min-480} {
    padding: 0 0 0.75em;
  }

  .ant-select {
    width: 125px;
  }

  .ant-input-search {
    @media #{$max-480} {
      width: 47vw;
    }
  }
  .ant-select-selector {
    font-size: 0.9em;
  }

  .ant-form-inline {
    @media #{$max-480} {
      justify-content: center;
    }
    .ant-form-item {
      margin-right: 0.6em;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.MyListingsSearch__label {
  &:after {
    content: 'Search by';
    @media #{$max-480} {
      content: 'Search';
    }
  }
}
