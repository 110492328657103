@import 'styles/base';

.updatePuppyRegistration__modal {
  &__title {
    font-size: 16px;
    .anticon {
      padding-right: 5px;
    }
  }
  form {
    .ant-form-item {
      width: 100% !important;
    }
    button {
      &:first-child {
        margin-left: 0;
      }
      margin-left: 16px;
    }
  }
}
