@import 'styles/base';

.not-found #root {
  background: $background;
}

.NotFound {
  text-align: center;
  margin-top: 10%;
}

.NotFound__title {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 20px;
}