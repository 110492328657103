.ColumnSearch {
  padding: 8px;
  &__input {
    display: block;
    margin-bottom: 8px;
    width: 188px;
  }
  .ant-btn {
    width: 90px;
  }
}