@import 'styles/base';

.PuppiesSummary {
  margin-bottom: 24px;

  .ant-card-head {
    border-bottom: none;
    font-weight: bold;
  }

  .ant-card-body {
    padding-top: 0;
  }

  .ant-list-grid .ant-col > .ant-list-item.display-none {
    display: none;
  }

  .ant-list-item {
    padding: 0;
    font-size: 16px;
  }

  .ant-list-item-meta-avatar {
    a {
      color: $base-font-color;
      &:hover {
        color: $color-primary;
      }
    }
  }

  .ant-list-item-meta-description {
    color: $base-font-color;
    display: flex;
    justify-content: flex-end;
  }

  .PuppiesSummary__litter-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    font-size: 70px;
    color: greyScale(0.5);
  }

  .PuppySummary__views {
    font-size: $base-font-size;

    .anticon {
      margin: 0 5px;
    }
  }

  .PuppiesSummary__show-toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $orange-6;
    font-weight: bold;
    margin-left: 20px;

    .anticon {
      color: black;
      margin-left: 5px;
    }
  }

  .PuppiesSummary__tools {
    display: flex;
    justify-content: space-between;
  }
}
