@import 'styles/base';

.HealthDocUploaderForm {
  margin-top: 1.5em;
  .ant-btn-primary {
    &[disabled] {
      color: greyScale(0.9);
      opacity: 0.35;
      background-color: $orange-6;
      border-color: transparent;
    }
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-checkbox-wrapper {
    margin-bottom: 12px;
  }
}

.HealthDocUploaderForm__title {
  &.ant-typography {
    text-align: center;
    margin-bottom: 1.25em;
  }
}

.HealthDocUploaderForm__alert {
  &.ant-typography {
    margin: 0;
    line-height: 1.28;
  }
}

.HealthDocUploaderForm__warningMessage {
  margin-bottom: 1.5em;
}
