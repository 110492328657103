@import 'styles/base';

.HealthDocsStatus__title {
  text-align: center;
}

.HealthDocsStatus__wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid greyScale(0.07);
  padding: 0.8em;
  align-items: center;
}

.HealthDocsStatus__wrapper__left {
  display: flex;

  .HealthDocBadge {
    margin-right: 0.8em;
  }
}
