@import 'styles/base';

.section-loading {
  padding-top: 10vh;
  color: rgba($black, 0.45);
  text-align: center;
  width: 100%;
  @media #{$min-1200} {
    padding-top: 25px;
  }
}
