@import 'styles/base.scss';

.UpcomingCard {
  padding: 15px;
  width: 100%;

  @media #{$min-1200} {
    padding: 20px;
  }

  .EntityPhoto__image {
    border: 2px solid $yellow;
  }
}
.UpcomingCard__thumbnail {
  height: 65px;
  width: 65px;
  border-radius: 5px;
  border: 2px solid $yellow;
  background-size: cover;
  background-position: center;
  text-align: center;
  font-size: 40px;
  line-height: 55px;
}

.UpcomingCard__info {
  padding: 0 0 0 7px;

  .text-right {
    text-align: right;
  }
}

.UpcomingCard__info-title {
  font-size: 18px;
  color: greyScale(0.85);
  font-weight: bold;

  width: 90%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.UpcomingCard__info-icon {
  color: $yellow;
  margin-right: 4px;
}

.UpcomingCard__info-text,
.UpcomingCard__info-due-date-label {
  color: greyScale(0.85);
  font-size: 14px;
  width: 80%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media #{$min-1200} {
    font-size: 16px;
  }
}

.UpcomingCard__info-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.UpcomingCard--past-due {
  .EntityPhoto__image {
    border-color: $red;
  }

  .UpcomingCard__info-due-date-label {
    color: $red;
  }

  .UpcomingCard__info-icon {
    color: $red;
  }
}
