@import 'styles/base';

.Collapsable {
  margin-bottom: 25px;
  &--warning {
    border-color: $warning;
    border-bottom: 1px solid $warning;
    .ant-collapse-item {
      border-bottom: none;
    }
  }
  &--success {
    border-color: $success;
    border-bottom: 1px solid $success;
    .ant-collapse-item {
      border-bottom: none;
    }
  }
  &--pending {
    border-color: $blue-3;
    border-bottom: 1px solid $blue-3;
    .ant-collapse-item {
      border-bottom: none;
    }
  }
}
.Collapsable__panel {
  &.ant-collapse-item {
    > .ant-collapse-header {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      padding: 12px 64px 12px 8px;
      @media #{$min-1200} {
        padding: 12px 76px 12px 12px;
      }
    }
  }
}
.CollapsablePanelHeader {
  display: flex;
  align-items: center;
  .anticon {
    font-size: 1.4rem;
    margin-right: 8px;
    line-height: 1.1;
  }
}
.CollapsablePanelHeader__statusIcon {
  &.anticon {
    font-size: 1.2rem;
  }
  &--warning {
    color: $warning;
  }
  &--success {
    color: $success;
  }
  &--pending {
    border: 1px solid $blue-3;
    border-radius: 100%;
    padding: 2px;
    display: flex;
    align-items: center;
    color: $blue-3;
    &.anticon {
      font-size: .85em;
    }
  }
}
.CollapsablePanelHeader__title {
  font-family: $secondary-font-family;
  font-size: 1rem;
  font-weight: $font-weight-bold;
  line-height: 1;
}

.CollapsableExpandIcon {
  position: absolute;
  right: 8px;
  @media #{$min-1200} {
    right: 12px;
  }
  .anticon {
    margin-left: 0.25rem;
  }
  &__custom {
    color: $color-primary;
  }
  &__goback {
    margin-right: 6px;
    color: $color-primary;
  }
}
