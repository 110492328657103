@import 'styles/base';

.Transaction_Card {
  background-color: $white;
  border-radius: $border-radius;
  margin-bottom: 12px;
  padding: 16px;
  &:last-of-type {
    margin-bottom: 30px;
  }
}

.Transaction_Card__payment {
  font-size: 18px;
  margin-bottom: 7px;
}
.Transaction_Card__payment-type {
  color: $green-6;
  font-weight: 600;
}

.Transaction_Card__total {
  font-size: 20px;
  color: $black;
}

.Transaction_Card__payment-detail {
  &--alert {
    color: $error;
  }
}

.Transaction_Card__payment-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 63%;
  a {
    font-weight: 600;
  }
}

.Transaction_Card__see-details {
  &.ant-btn {
    padding: 0;
    color: $color-primary;

    .anticon {
      color: greyScale(0.85);
    }
  }
}
