@import 'styles/base';

.BreedingForm__state {
  .ant-checkbox-inner {
    margin-bottom: -4px;
  }
}

.BreedingForm__has-complementary {
  margin-bottom: 0;
}


.BreedingForm__usda-radio {
  .ant-radio-wrapper {
    white-space: initial;
  }
}

.BreedingForm__practice-list {
  list-style-type: decimal;
  padding-inline-start: 15px;
}

.BreedingForm__usda-option {
  display: block;

  span {
    font-size: $font-small;
  }
}

