@import "styles/base";

.UpToDateInfoBar__label {
    padding: 5px 15px 8px;
    text-align: center;
    line-height: 1.2;
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;

  h3 {
    font-family: $primary-font-family;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    color: greyScale(0.85);
  }
  p {
    margin: 0;
    color: greyScale(0.85);
    font-size: 14px;
  }
}