@import 'styles/base';

.UpToDateInfoBar {
  border-top: 1px solid $default-input-border-color;
  display: flex;

  &--summary {
    width: 100%;
    > div {
      .UpToDateInfoBarButton,
      .UpToDateInfoBar__label {
        width: 25%;
      }
      .UpToDateInfoBarButton {
        p {
          display: none;
          @media #{$min-1200} {
            margin: 0 0 0 10px;
            font-weight: 100;
            font-size: 16px;
            display: inline-block;
          }
        }
      }
      .UpToDateInfoBar__label {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media #{$max-1200} {
          padding: 5px 0 8px;
        }
        @media #{$min-1200} {
          flex-direction: row;
        }
        p {
          @media #{$min-1200} {
            margin-right: 2px;
          }
        }
        @media #{$min-1200} {
          h3,
          p {
            font-size: 16px;
          }
        }
      }
    }
  }

  &--backlog {
    div .UpToDateInfoBarButton,
    div .UpToDateInfoBar__label{
      border: none;
      @media #{$max-1200} {
        width: 16.66%;
      }
    }
  }
}
