@import 'styles/base';

.HealthDocDueDate {
  font-weight: bold;
}

.HealthDocDueDate--default {
  font-weight: normal;
}

.HealthDocDueDate--green {
  color: $green-6;
}

.HealthDocDueDate--orange {
  color: $orange-6;
}

.HealthDocDueDate--red {
  color: $red;
}
