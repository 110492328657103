@import 'styles/base';

.PageLayout__PuppyDetail {
  .section-loading {
    @media #{$min-1200} {
      padding-top: 10vh;
    }
  }
  .PuppyDetail__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  .PuppyDetail__header-left,
  .PuppyDetail__header-right {
    display: inline-flex;
    align-items: center;
    grid-gap: 0.8rem;
  }
  .PuppyDetail__header-right {
    justify-content: flex-end;
  }

  .PuppyDetail__tag-breeder-dashboard-status {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    margin: 0;
    font-size: 14px;
    padding: 0 8px 3px;
    text-transform: capitalize;
  }
}
