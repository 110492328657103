.HealthDocDrawer {
  .drawerHeader {
    display: flex;
    align-items: center;

    .puppyNameContainer {
      display: flex;
      align-items: center;

      :last-child {
        margin-left: 4px;
      }
    }

    .puppyName {
      max-width: 135px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 4px;
    }

    > span[role='img'] {
      margin-right: 8px;
    }
  }

  .customerContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
