@import 'styles/base';

.PuppyPhotos,
.PuppyVideo {
  .ant-form-item-label,
  .ant-upload.ant-upload-select {
    display: block;
  }

  .without-video {
    .PuppyVideo__description {
      margin-bottom: 5px;
    }

    .ant-alert-warning {
      margin-bottom: 10px;
    }
  }
}

.PuppyPhotos__description,
.PuppyVideo__description {
  font-size: 14px;
}
.ant-form-large {
  .PuppyPhotos__description,
  .PuppyVideo__description {
    font-size: 16px;
  }
}

.PuppyPhotos__section-title {
  display: flex;
  justify-content: space-between;
  color: greyScale(0.5);
  background-color: greyScale(0.05);
  margin: 15px 0 15px;
  text-transform: uppercase;
  padding: 8px;
  font-family: $primary-font-family;
  font-size: 16px;
}

.PuppyPhotos__droppable-wrapper {
  padding: 8px 0;
  &.dragging-over {
    background-color: greyScale(0.08);
  }
}

.cloudflareVideoWrapper {
  background-color: $black;
  min-height: 295px;
}
