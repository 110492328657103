@import 'styles/base';

.MyParentsLoadMore {
  &.ant-btn {
    color: $color-primary;
    &:hover {
      filter: brightness(1.2);
    }
  }
}
