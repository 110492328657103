@import 'styles/base';

.PageLayout__MyListings {
  .PageLayout__content {
    padding: 12px;

    @media #{$max-1200} {
      padding: 0;
    }
  }

  .ant-tabs-tab {
    padding-top: 0;
  }

  .ant-tabs-nav-wrap {
    background-color: $background;
  }
}
