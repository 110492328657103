@import 'styles/base';

.MyListingsDashboardStatusTabs {
  .ant-tabs-nav-list {
    width: 100%;
    border-bottom: 1px solid greyScale(0.1);
    position: relative;
    display: flex;
    transition: transform 0.3s;
  }

  .ant-tabs-tab {
    flex: 1;
    justify-content: center;
    margin: 0 15px;
    font-size: 14px;
    text-align: center;

    .ant-badge-count {
      background: transparent;
      color: greyScale(0.85);
      box-shadow: 0 0 0 1px greyScale(0.85);
    }

    .anticon-loading {
      color: greyScale(0.3);
    }

    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $font-color-dark-1;
    }
  }

  .ant-tabs-ink-bar {
    background: greyScale(0.5);
    height: 2px;
    bottom: 0;
  }

  .ant-tabs-ink-bar-animated {
    transition: width 0.3s, left 0.3s, right 0.3s;
  }

  .ant-tabs-content-holder {
    padding: 0.8em 0.8em 1em;
  }

  .ant-tabs-nav-operations-hidden {
    display: none;
  }
}

.MyListingsDashboardStatusTab__label {
  text-transform: capitalize;
}
