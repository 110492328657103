@import 'styles/base';

.MyListingsCards__wrapper {
  position: relative;
  
  .ant-empty {
    margin-top: 3.5em;
  }
}

.MyListingsCards__loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(207, 207, 207, 0.7);
  z-index: 1000;
  display: block;
}