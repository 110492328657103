@import 'styles/base';

.PuppyDetailTile {
  border: 1px solid greyScale(0.15);
  border-radius: $border-radius;
  box-shadow: 0 0 4px greyScale(0.07);
  text-align: center;
  background-color: $white;
  padding: 1.25em;
  width: 100%;
  min-height: 140px;

  .ant-badge {
    font-size: 50px;
    color: greyScale(0.85);
    > .anticon {
      font-size: 1em;
    }

    .ant-scroll-number-custom-component {
      background-color: $light-gray;
      border-radius: 50%;
      padding: 0.5em;
      font-size: 0.24em;
      transform: translate(40%, -15%);
    }
  }
}

.PuppyDetailTile__title {
  margin: 0.5em 0 0.25em;
  font-family: $primary-font-family;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
}

.PuppyDetailTile__status-text {
  margin: 0;
  line-height: normal;
  font-size: 14px;
  min-height: 18px;
}

.PuppyDetailTile--clickable {
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    border-color: $white;
  }
}

.PuppyDetailTile--success {
  .ant-badge {
    .ant-scroll-number-custom-component {
      background-color: $success;
    }
  }
}

.PuppyDetailTile--pending {
  .ant-badge {
    .ant-scroll-number-custom-component {
      background-color: $blue-3;
    }
  }
}

.PuppyDetailTile--error {
  .ant-badge {
    .ant-scroll-number-custom-component {
      background-color: $error;
    }
  }
}

.PuppyDetailTile--warning {
  .ant-badge {
    .ant-scroll-number-custom-component {
      background-color: $warning;
    }
  }
}
