@import 'styles/base';

.BaseLayout__header {
  background-color: $white;
  padding: 0;
  box-shadow: 0 1px 4px grey;
  position: relative;
  transition: width, 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &.ant-layout-header {
    box-shadow: 0 2px 4px greyScale(0.3);
    height: 52px;
    line-height: 64px;
    z-index: $z-index-header;
    background-color: $white;
  }

  @media #{$max-1200} {
    padding-left: 0;
  }
}

.BaseLayout__header-options {
  display: flex;
  align-items: center;
}

.BaseLayout__header-search {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 30px;
  cursor: pointer;
}

.BaseLayout__header-search .anticon-phone {
  font-size: 20px;
}

.BaseLayout__header-block.ant-layout-header {
  @media #{$min-1200} {
    padding-left: 80px;
  }
}

.BaseLayout__header-block-collapsed.ant-layout-header {
  padding-left: 300px;
}

.BaseLayout__header-layer {
  position: relative;

  &.ant-layout-header {
    height: 64px;
    line-height: 64px;
  }
}

.BaseLayout__header-content-left {
  display: flex;
  align-items: center;
  justify-content: center;
}
.BaseLayout__header-logo {
  margin-right: 16px;
  display: inline-flex;

  img {
    width: 89%;
  }
}
.BaseLayout__header-hamburger {
  cursor: pointer;
  font-size: 22px;
}
