@import 'styles/base';

.Login ~ .Navigation {
  display: none;
}

.Navigation {
  overflow-y: auto;
  background: $blue-1;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
  min-height: 100%;

  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon,
  .ant-menu-item .anticon + span,
  .ant-menu-submenu-title .anticon + span,
  a {
    font-size: 16px;
    color: $base-font-color;
  }

  .ant-menu-item a::before{
    width: 100%;
  }

  .Navigation__link {
    width: 100%;
    display: inline-flex;
    padding-left: 35px;
    margin-left: -37px;
  }

  &--collapsed {
    width: 80px;

    .Navigation__link {
      padding-left: 84px;
      margin-left: -53px;
    }
  }

  &--uncollapsed {
    width: 300px;
  }

  @media #{$max-1200} {
    padding-left: 0;
  }
}

.Navigation__ps-logo {
  padding: 2px 27px 26px;
  overflow: hidden;
  position: relative;

  a{
    display: block;
  }

  svg {
    transition: left .2s;
    left: 0;
    top: 9px;
    position: relative;
  }

  &--uncollapsed {
    padding: 2px 12px 26px;

    svg {
      left: -135px;
    }
  }

  .breedHubLogo-vert-bar {
    fill: $black;
    opacity: 0.25;
  }
}

.Navigation__ps-logo-text {
  margin: 0.3rem 0.3rem 0;
  width: 120px;
  animation: fadein;
  animation-duration: 0.2s;
}

.Navigation__divider {
  min-width: 80%;
  padding: 0 27px;
  background: greyScale(0.45);
  width: 80%;
  margin: 0 auto;
  height: 1px;
}

.Navigation__item {
  font-weight: bold;
}

.Navigation__submenu {

  &.ant-menu-submenu-inline {
    padding-right: 23px;
  }

  .ant-menu-submenu-title .ant-menu-submenu-arrow {
    right: 6px;
  }

  > div {
    font-weight: bold;
  }

  > .ant-menu {
    background: $blue-1;
  }
}
.ant-menu-submenu {
  .Navigation__link {
    min-width: 292px;
    display: block;
  }
}

.ant-menu-submenu-popup {
  .Navigation__link {
    min-width: 100%;
    display: inline-flex;
  }
}
