@import "styles/base";

.BreederImpersonate {
  margin-top: 16px;
}

.BreederImpersonate__title {
  font-size: 16px;
}

.BreederImpersonate__search {
  margin-bottom: 16px;
}