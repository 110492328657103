@import 'styles/base';

.ColumnPuppyId__photo-wrapper {
  position: relative;

  &--is-locked {
    .EntityPhoto {
      opacity: 0.4;
    }
  }
}
