@import '../healthDocBadgeStatus/HealthDocBadgeStatus';

.HealthDocumentBadgeStatusLegend {
  font-size: 13px;
}

.HealthDocumentBadgeStatusLegend__item {
  display: flex;
  align-items: center;

  .badge {
    border-radius: 50%;
    height: 15px;
    width: 15px;
    border: 1px solid greyScale(0.15);
    background-color: $white;
    display: inline-block;
    margin-right: 0.25rem;
  }

  .badge--not-uploaded {
    border: 1px solid greyScale(0.15);
    background-color: $white;
  }

  .badge--pending-review {
    border-color: $color-document-workflow-status-pending-review;
    background-color: rgba(
      $color-document-workflow-status-pending-review,
      0.07
    );
  }

  .badge--approved {
    border-color: $color-document-workflow-status-approved;
    background-color: rgba($color-document-workflow-status-approved, 0.07);
  }

  .badge--rejected {
    border-color: $color-document-workflow-status-rejected;
    background-color: rgba($color-document-workflow-status-rejected, 0.07);
  }

  .badge--expired {
    border-color: $color-document-workflow-status-expired;
    background-color: rgba($color-document-workflow-status-expired, 0.07);
  }

  .badge--awaiting-disclosure {
    border-color: $color-document-workflow-status-awaiting-disclosure;
    background-color: rgba($color-document-workflow-status-awaiting-disclosure, 0.07);
  }
}
