@import 'styles/base';

.MyListingsTableSold {
  .ant-table-footer {
    background-color: $background;
  }

  .HealthDocDueDate {
    min-width: 85px;
    max-width: 85px;
    display: inline-block;
    text-align: right;
  }
}

.UploadHealthDocs {
  & > .anticon + span {
    color: $color-primary;
  }
  &:hover,
  &:focus,
  &:active {
    color: $color-primary;
  }
}
