@import 'styles/base';

.StaticPage {
  padding-bottom: 5em;
  max-width: 992px;
  margin: 0 auto;
  @media #{$min-1200} {
    padding-bottom: 0;
  }
  h2, h3 {
    color: $base-font-color;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
  }

  ul {
    padding-left: 15px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  a{
    color: $orange-6;
  }
}

.StaticPage--simplepage {
  h2 {
    border-bottom: 1px solid greyScale(0.15);
    padding-bottom: 8px;
  }
}

.StaticPage__collapse-panel {
  background: $white;
  margin-bottom: 16px;

  .ant-collapse-header {
    font-size: 20px;
    border-bottom: 1px solid greyScale(0.15);
  }
  .ant-collapse-content-box >div {
    font-size: 16px;
  }
}

.StaticPage--webinar {
  color: greyScale(0.65);
  p {
    font-size: 16px;
  }
  ul {
    padding-left: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;

      &:before {
        content: "\2713";
        background: $green-6;
        height: 13px;
        width: 13px;
        color: $white;
        border-radius: 100%;
        font-size: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 7px;
      }
    }
  }
}

.CollapsablePanelHeaderWebinars__title {
  color: $font-color-dark-1;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.CollapsablePanelHeaderWebinars__subtitle {
  color: $base-font-color;
  font-size: 14px;
  letter-spacing: 0;
}

.StaticPage__collapse-link{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;

  span {
    text-transform: capitalize;

    &:first-of-type {
      margin-right: 5px;
    }
  }

  a {
    border-radius: 4px;
    font-weight: bold;
    color: $base-font-color;
    border: 1px solid $orange-6;
    padding: 0 13px 3px;
    font-size: 14px;
  }
}

.StaticPage__collapse-description {
  border-bottom: 1px solid greyScale(0.15);
  padding-bottom: 16px;
}

.StaticPage__collapse--date {
  color: greyScale(0.65);
  span {
    font-size: 16px;

    &:last-of-type {
      margin-left: 5px;
    }
  }
}
